import { createChatBotMessage } from 'react-chatbot-kit';
import MyAvatar from "../myAvatar/myAvatar";
import MyHeader from "../myHeader/myHeader";
import MyCustomChatMessage from "../MyCustomChatMessage/MyCustomChatMessage";
import MyCustomUserChatMessage from "../MyCustomUserChatMessage/MyCustomUserChatMessage";

const config = {
  botName: 'MCA',
  initialMessages: [createChatBotMessage(`Salut ! Je suis L' intelligence artificielle conçue pour répondre à vos questions et résoudre vos problèmes. Comment puis-je vous assister ?`, {})],
  customComponents: {
    header:  (props) => <MyHeader {...props} />,
    botAvatar: (props) => <MyAvatar {...props} />,
    botChatMessage: (props) => <MyCustomChatMessage {...props} />,
    userChatMessage: (props) => <MyCustomUserChatMessage {...props} />
  },
  state: {},
  idQt: null
};

export default config;
