import './App.css';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import React, { useEffect, useState } from 'react';
import {getChatbot, getEntreprise} from "./api/service";
import { createChatBotMessage } from 'react-chatbot-kit';

function App() {
    const [botConfig, setBotConfig] = useState(null); // Initialiser avec null
    const [loading, setLoading] = useState(true); // État de chargement

    const saveMessages = (messages, HTMLString) => {
        localStorage.setItem('chat_messages', JSON.stringify(messages));
    };

    const loadMessages = () => {
        const messages = JSON.parse(localStorage.getItem('chat_messages'));
        return messages;
    };

    useEffect(() => {
        const getIdChat = async () => {
            try {
                const data = await getChatbot(window.location.href.includes('chatbot_id=') ? window.location.href.split('chatbot_id=')[1].split('&')[0] : null);
                if (data && data.data && data.data.id && data.data.modules && data.data.modules.chatbot) {
                    localStorage.setItem('chatbot', JSON.stringify(Object.assign({id: data.data.id}, data.data.modules.chatbot)));
                    
                    // Récupérer le message d'introduction
                    const intro = data.data.messages.intro || `Salut ! Je suis L' intelligence artificielle conçue pour répondre à vos questions et résoudre vos problèmes. Comment puis-je vous assister ?`;
                    localStorage.setItem('messages', JSON.stringify(data.data.messages))
                    const entreprise = await getEntreprise(data.data.entreprise_id);
                    if (entreprise && entreprise.data && entreprise.data.nom) {
                        localStorage.setItem('entreprise', JSON.stringify({id: entreprise.data.id, nom: entreprise.data.nom}));
                    }

                    // Mettez à jour la config du chatbot avec le message d'intro
                    setBotConfig({
                        ...config,
                        initialMessages: [createChatBotMessage(intro)],
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Fin du chargement
            }
        };

        getIdChat();
    }, []);

    useEffect(() => {
        if (!loading && document.querySelector('.react-chatbot-kit-chat-btn-send')) {
            // Manipuler l'élément DOM après que React l'ait rendu
            document.querySelector('.react-chatbot-kit-chat-btn-send').innerHTML = `
                <svg class="react-chatbot-kit-chat-btn-send-icon" xmlns="http://www.w3.org/2000/svg" width="71" height="70" viewBox="0 0 71 70">
                    <g> <path d="M26.4168 54.9786C25.9023 55.48 25.2958 55.6696 24.5973 55.5476C23.8987 55.4256 23.4105 55.0289 23.1326 54.3574L19.5683 45.7441L32.2821 36.4845L16.7429 38.9163L13.1786 30.303C12.9007 29.6316 12.9658 29.0058 13.3739 28.4259C13.7819 27.8459 14.3451 27.5516 15.0635 27.5428L48.5104 27.7651C49.4097 27.791 50.0255 28.2056 50.3579 29.0088C50.6903 29.8121 50.5465 30.5381 49.9264 31.1869L26.4168 54.9786Z"/>
                    </g>
                </svg>`;
        }
    }, [loading]); // Exécuter cet effet après le chargement complet

    if (loading) {
        return <div>Chargement...</div>; // Affichage pendant le chargement
    }

    return (
        <div>
            {botConfig && (
                <Chatbot
                    config={botConfig} // Utilisation de la configuration mise à jour
                    headerText='MCA'
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                    saveMessages={saveMessages}
                    messageHistory={loadMessages()}
                    placeholderText='Composez votre message...'
                    validator={(msg) => msg.length > 0 && msg.length < 401}
                />
            )}
        </div>
    );
}

export default App;
