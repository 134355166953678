import React, {useState} from 'react'
import './myHeader.css'


const MyHeader = () => {
    const [openNav, setOpenNav] = useState(false);
    const color = localStorage.getItem('chatbot') && JSON.parse(localStorage.getItem('chatbot')) && JSON.parse(localStorage.getItem('chatbot')).color;
    const name = localStorage.getItem('entreprise') && JSON.parse(localStorage.getItem('entreprise')) && JSON.parse(localStorage.getItem('entreprise')).nom;
    const logo = localStorage.getItem('chatbot') && JSON.parse(localStorage.getItem('chatbot')) && JSON.parse(localStorage.getItem('chatbot')).logo;

    return (
        <div style={{ backgroundColor: (color || '#1972F5'), position: 'relative' }}>
            <div style={{ backgroundColor: (color || '#1972F5'), padding: "25px", display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                { logo && <img src={logo} style={{width: "45px"}}/>}
                { !logo && <img src="/assets/chat.svg" style={{width: "45px"}}/>}
                <div style={{ color: '#ffffff', textAlign: "center", padding: '0 10px', fontSize: '16px', fontWeight: 'bold' }}>{name || 'MCA'}</div>
                {/*<div className={'dots'} onClick={ () => setOpenNav((!openNav))}><span></span></div>*/}
            </div>
            <div className={'navBar'} style={{display: (openNav ? 'flex' : 'none')}}>

            </div>
        </div>
    )
}

export default MyHeader
