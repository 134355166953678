import React  from 'react'
import { Avatar } from '@mui/material'
import './myAvatar.css'


const MyAvatar = () => {
    const logo = localStorage.getItem('chatbot') && JSON.parse(localStorage.getItem('chatbot')) && JSON.parse(localStorage.getItem('chatbot')).logo;

    return (
        <Avatar
        alt="bot"
        src={logo ? logo : "/assets/chat.svg"}
        sx={{ width: 35, height: 35 }}
      />
    )
}

export default MyAvatar
